:root {
  --background: #fff;
  --offset-base-size: 2px;

  --common-border-radius: 14px;

  --text-color-primary: #292929;
  --text-color-secondary: #fff;

  --text-color-h1: #141414;
  --text-color-black: #000;
  --text-color-input: #3d3d3d;
  --text-input-placeholder: #7a7a7a;

  --bg-input-hover: #fafafa;
  --bg-input-disabled: #ebebeb;

  --bg-button-default: #85cfff;
  --bg-button-hover: #5cbfff;
  --bg-button-active: #1fa7ff;
  --bg-button-disabled: #e0e0e0;

  --default-color: #0032ff;
  --selected-color: #ff0000;
  --changing-color: #d252e1;
  --modified-color: #7fe051;
  --invisible-color: #ffffff;

  --common-transition: all 0.3s ease-in;
}

@-moz-document url-prefix() {
  :root {
    scrollbar-color: var(--scrollbar-color);
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  color: var(--text-color-primary);
  background: var(--background);
}

/* Typography classes */

.text {
  margin: 0;
  padding: 0;
}

.text_color_primary {
  color: var(--text-color-primary);
}

.text_color_secondary {
  color: var(--text-color-secondary);
}

.text_color_h1 {
  color: var(--text-color-h1);
}

.text_color_black {
  color: var(--text-color-black);
}

.text_color_input {
  color: var(--text-color-input);
}

.text_color_link {
  color: var(--default-color);
}

.text_type_h1 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 134px;
  line-height: 163px;
}

.text_type_h2 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 49px;
}

.text_type_h3 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
}

.text_type_ticker {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 44px;
  line-height: 54px;
}

.text_type_button {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
}

.text_type_input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
}

.text_type_input-lim {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.text_type_circle {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.text_type_column {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

.text_type_fibonacci {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
