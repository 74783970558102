.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
}

.form {
  display: flex;
  min-width: 600px;
  gap: 12px;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 78px 16px;
}

.item {
  margin: 0;
  padding: 0;  
}
