.container {
  width: 100%;
  height: calc(100% - 185px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 48px;
}

.form {
  display: flex;
  min-width: 600px;
  gap: 12px;
  align-items: center;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  gap: 48px 16px;
  flex-grow: 1;
}

.item {
  margin: 0;
  padding: 0;  
}

.smallLeftMargin {
  margin-left: 28px;
}

.mediumLeftMargin {
  margin-left: 40px;
}

.largeLeftMargin {
  margin-left: 68px;
}

.exactWidth {
  width: 200px;
}
